import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Icon, { GlyphNames } from './icon';

const Modal = ({ children, title, onClose, onConfirm }) => {

  const [container] = useState(() => {
    const container = document.createElement('div');
    container.id = 'modal-container';
    return container;
  });

  useEffect(() => {
    if (container) {
      document.body.appendChild(container);
    }
    return () => {
      container.remove();
    };
  }, [container]);

  return createPortal(
    <div className="modal-wrapper">
      <div className="modal">
        <div className="modal-header">
          <h1 className="modal-title">{title}</h1>
          <button className="modal-close-button" aria-label="Close modal" onClick={() => onClose()}>
            <Icon glyphName={GlyphNames.cross}/>
          </button>
        </div>
        <div className="modal-body">
          {children}
        </div>
        <div className="modal-actions">
          <button className="btn admin-btn modal-btn" onClick={() => onClose()}>Cancel</button>
          <button className="btn admin-btn modal-btn" onClick={() => onConfirm()}>Confirm</button>
        </div>
      </div>
    </div>, container);
};

export default Modal;