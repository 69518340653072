import React from 'react';
import { getResourceText } from '../lib/language';

const LabelSelect = ({ value = '', options = [], onChange, labelKey, error = false, classNamePrefix = '', readOnly = false }) => (
  <div className={`${classNamePrefix}__label-inputs-container`}>
    <label className={`${classNamePrefix}__label-input-container`}>
      <span className={`${classNamePrefix}__label`}>{getResourceText(labelKey)}</span>
      <div className={`${classNamePrefix}__input-container`}>
        <select onChange={({ target: { value } }) => onChange(value)} className={`${classNamePrefix}__select`} disabled={readOnly} value={value}>
          {options.map(({ label, value: optionValue }) => <option key={`${labelKey}-${optionValue}`} value={optionValue} className={`${classNamePrefix}__option`}>{label}</option>)}
        </select>
      </div>
    </label>
    {error ? <span className={`${classNamePrefix}__error`}>{getResourceText(`${labelKey}Error`)}</span> : null}
  </div>);

export default LabelSelect;