import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { addDays, addMonths, isBefore, isEqual } from 'date-fns';
import AdminPage from '../../../../../../components/adminPage';
import { getResourceText } from '../../../../../../lib/language';
import { LoadingSpinnerButton } from '../../../../../../components/loading';
import { useApi } from '../../../../../../hooks/api';
import LabelSelect from '../../../../../../components/labelSelect';
import DateInput from '../../../../../../components/dateInput';
import { formatDate, formatDateWithTime } from '../../../../../../lib/date';
import LabelInput from '../../../../../../components/labelInput';
import Modal from '../../../../../../components/modal';
import '../../../../../../sass/_admin-subscriptions.scss';

const AdminEditSubscription = ({ organisationId, subscriptionId }) => {
  const [subscription, setSubscription] = useState();
  const [organisation, setOrganisation] = useState();
  const [resourcePacks, setResourcePacks] = useState();
  const [resourcePack, setResourcePack] = useState();
  const [resourcePackError, setResourcePackError] = useState(false);
  const [startDate, setStartDate] = useState(formatDate(new Date()));
  const [startDateError, setStartDateError] = useState(false);
  const [endDate, setEndDate] = useState();
  const [endDateError, setEndDateError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [, setError] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const {
    admin: {
      updateSubscription: apiUpdateSubscription,
      getSubscription,
      getOrganisations,
      getResourcePacks,
      deleteSubscription: apiDeleteSubscription,
    },
  } = useApi();

  useEffect(() => {
    const getData = async () => {
      const [
        { status: subscriptionStatus, json: subscriptionData },
        { status: organisationStatus, json: organisationsData },
        { status: resourcePackStatus, json: resourcePackData },
      ] = await Promise.all([
        getSubscription({ organisationId, subscriptionId }),
        getOrganisations(),
        getResourcePacks(),
      ]);

      if (organisationStatus === 200 && resourcePackStatus === 200 && subscriptionStatus === 200) {
        setResourcePacks(
          resourcePackData.map(({ resourceName, resourceId }) => ({ label: resourceName, value: resourceId }))
        );
        setSubscription(subscriptionData);
        setOrganisation(
          organisationsData.find(({ resourceId }) => resourceId === subscriptionData.organisationId)?.resourceName
        );
        setResourcePack(subscriptionData.resourcePackId);
        setStartDate(subscriptionData.startDate);
        setEndDate(subscriptionData.endDate);
      } else {
        setError(true);
      }
      setLoading(false);
    };
    getData().catch(() => {
      setError(true);
    });
  }, [getOrganisations, getResourcePacks, getSubscription, organisationId, subscriptionId]);

  const validate = () => {
    let isValid = true;
    setResourcePackError(!resourcePack);
    setStartDateError(!startDate);
    setEndDateError(!endDate);

    if (isBefore(new Date(endDate), new Date(startDate)) || isEqual(new Date(startDate), new Date(endDate))) {
      setStartDateError(true);
      setEndDateError(true);
      isValid = false;
    }
    return isValid && !!organisation && !!resourcePack && !!startDate && !!endDate;
  };

  const resetValidation = () => {
    setResourcePackError(false);
    setStartDateError(false);
    setEndDateError(false);
  };

  const updateSubscription = async (event) => {
    event.preventDefault();
    resetValidation();
    setApiError(false);

    if (validate()) {
      setLoading(true);

      const { status } = await apiUpdateSubscription({
        subscriptionId,
        organisationId,
        resourcePackId: resourcePack,
        startDate,
        endDate,
      });

      if (status === 200) {
        await navigate('/admin/subscriptions');
      } else {
        setApiError(true);
      }
      setLoading(false);
    }
    return false;
  };

  const updateEndDateMonths = (months) => {
    setEndDate(formatDate(addMonths(new Date(startDate), months)));
    return false;
  };

  const deleteSubscription = async () => {
    const { status } = await apiDeleteSubscription({ organisationId, subscriptionId });
    setShowDeleteConfirm(false);

    if (status === 200) {
      await navigate('/admin/subscriptions');
    } else {
      setApiError(true);
    }
  };

  const RemoveSubscriptionModal = ({ onClose, onConfirm }) => (
    <Modal onClose={onClose} onConfirm={onConfirm} title={getResourceText('confirmDeleteSubscriptionHeading')}>
      <div className="admin-resource-pack-modal-text">{getResourceText('confirmDeleteSubscriptionText')}</div>
    </Modal>
  );

  return (
    <AdminPage withBack>
      <div className="admin-edit-subscription-container">
        <div className="admin-edit-subscription-container__main-heading-container">
          <h2 className="admin-edit-subscription-container__main-heading">
            <span className="admin-edit-subscription-container__heading-text">
              {getResourceText('updateSubscription')}
            </span>
            {loading ? <LoadingSpinnerButton /> : null}
          </h2>
          {subscription ? (
            <button
              className="btn admin-btn admin-edit-subscription-container__delete-button"
              onClick={() => setShowDeleteConfirm(true)}
            >
              {getResourceText('delete')}
            </button>
          ) : null}
          {showDeleteConfirm ? (
            <RemoveSubscriptionModal
              onClose={() => setShowDeleteConfirm(false)}
              onConfirm={() => deleteSubscription()}
            />
          ) : null}
        </div>
        {subscription && !loading ? (
          <div className="admin-edit-subscription-container__body-container">
            <form onSubmit={(event) => updateSubscription(event)}>
              <LabelInput
                labelKey="organisation"
                value={organisation}
                readOnly={true}
                classNamePrefix="admin-edit-subscription-container"
              />
              <LabelSelect
                labelKey="resourcePack"
                error={resourcePackError}
                onChange={setResourcePack}
                options={resourcePacks}
                value={resourcePack}
                classNamePrefix="admin-edit-subscription-container"
              />
              <DateInput
                labelKey="startDate"
                error={startDateError}
                onChange={setStartDate}
                value={startDate}
                classNamePrefix="admin-edit-subscription-container"
              />
              <DateInput
                labelKey="expiryDate"
                min={formatDate(addDays(new Date(), 1))}
                error={endDateError}
                onChange={setEndDate}
                value={endDate}
                classNamePrefix="admin-edit-subscription-container"
              />
              <div className="admin-edit-subscription-container__buttons-container">
                <button
                  type="button"
                  className="btn admin-btn admin-edit-subscription-container__quick-date-button"
                  onClick={() => updateEndDateMonths(3)}
                >
                  {getResourceText('threeMonths')}
                </button>
                <button
                  type="button"
                  className="btn admin-btn admin-edit-subscription-container__quick-date-button"
                  onClick={() => updateEndDateMonths(6)}
                >
                  {getResourceText('sixMonths')}
                </button>
                <button
                  type="button"
                  className="btn admin-btn admin-edit-subscription-container__quick-date-button"
                  onClick={() => updateEndDateMonths(12)}
                >
                  {getResourceText('twelveMonths')}
                </button>
              </div>
              <LabelInput
                labelKey="created"
                value={formatDateWithTime(subscription?.created)}
                readOnly={true}
                classNamePrefix="admin-edit-subscription-container"
              />
              <LabelInput
                labelKey="updated"
                value={formatDateWithTime(subscription?.updated)}
                readOnly={true}
                classNamePrefix="admin-edit-subscription-container"
              />
              <div className="admin-edit-subscription-container__actions">
                {apiError ? (
                  <span className="admin-edit-subscription-container__action-error">
                    {getResourceText('updateSubscriptionApiError')}
                  </span>
                ) : null}
                <button
                  type="button"
                  className="btn admin-btn admin-edit-subscription-container__action"
                  onClick={async () => await navigate('/admin/subscriptions')}
                >
                  {getResourceText('cancel')}
                </button>
                <button type="submit" className="btn admin-btn admin-edit-subscription-container__action">
                  {getResourceText('updateSubscription')}
                </button>
              </div>
            </form>
          </div>
        ) : null}
      </div>
    </AdminPage>
  );
};

export default AdminEditSubscription;
