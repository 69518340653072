import React from 'react';
import classNames from 'classnames';
import { getResourceText } from '../lib/language';

const DateInput = ({ value = '', onChange, labelKey, error = false, errorCode, classNamePrefix = '', readOnly = false, min, max }) => {

  return (
    <div className={`${classNamePrefix}__label-inputs-container`}>
      <label className={`${classNamePrefix}__label-input-container`}>
        <span className={`${classNamePrefix}__label`}>{getResourceText(labelKey)}</span>
        <div className={classNames(`${classNamePrefix}__input-container`)}>
          <input type="date" min={min} max={max} className={classNames(`${classNamePrefix}__input`)} value={value} onChange={!readOnly ? ({ target: { value: updatedValue } }) => onChange(updatedValue) : undefined} readOnly={readOnly}/>
        </div>
      </label>
      {error ? <span className={`${classNamePrefix}__error`}>{errorCode ? getResourceText(errorCode) : getResourceText(`${labelKey}Error`)}</span> : null}
    </div>
  );
};

export default DateInput;